<template>
  <a-row :gutter="10" style="margin-top: 10px">
    <a-form :layout="formLayout" labelAlign="left" :form="searchForm" @submit="handleSubmit">
      <a-col :span="6">
        <a-form-item label="装货地址" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-select
              v-model="form.start_address"
              mode="tags"
              placeholder="输入装货地址"
              @change="setStartAddress">
            <a-select-option v-for="item in addressList" :key="item.address_name">
              {{ item.address_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="吨位" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
              style="width: 80%"
              v-model="form.tonnage"
              placeholder="请输入吨位"
          />
          吨
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="卸货地址" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-select
              v-model="form.end_address"
              mode="tags"
              placeholder="输入卸货地址"
              @change="setEndAddress">
            <a-select-option v-for="item in addressList" :key="item.address_name">
              {{ item.address_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="油费" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
              style="width: 80%"
              v-model="form.refuel_amount"
              placeholder="请输入油费"
              suffix="元"
          />
          元
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="货物品名" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-select
              v-model="form.goods_name"
              mode="tags"
              placeholder="请输入货物品名"
              @change="setGoodsName">
            <a-select-option v-for="item in goodsList" :key="item.goods_name">
              {{ item.goods_name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="路费" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
              style="width: 80%"
              v-model="form.travel_amount"
              placeholder="请输入路费"
          />
          元
        </a-form-item>

      </a-col>
      <a-col :span="6">
        <a-form-item label="公里数" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
              style="width: 80%"
              v-model="form.kilometers"
              placeholder="请输入公里数"
          />
          公里
        </a-form-item>
        <a-form-item label="人工工资" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
              style="width: 80%"
              v-model="form.wages"
              placeholder="请输入人工工资"
          />
          元
        </a-form-item>

      </a-col>
      <a-col :span="24">
        <a-form-item label="其他费用" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-button type="primary" size="small" @click="handleAdd">增加一项</a-button>
          <div class="table">
            <div class="tr">
              <div class="td" style="width: 33%">费用名称</div>
              <div class="td" style="width:33%">费用金额</div>
              <div class="td" style="width: 33%">操作</div>
            </div>
            <div class="tr" v-for="(item,index) in form.other_cost">
              <div class="td" style="width: 33%">
                <a-input
                    style="width: 100%;"
                    allowClear
                    placeholder="请输入费用名称"
                    v-model="item.name"
                />
              </div>
              <div class="td" style="width: 33%">
                <a-input-number
                    style="width: 100%;"
                    allowClear
                    placeholder="请输入费用金额"
                    v-model="item.price"
                />
              </div>
              <div class="td" style="width: 33%">
                <a style="color: red" @click="handleDel(index)">删除</a>
              </div>
            </div>
          </div>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="成本总计" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input
              v-model="form.cost_total"
              placeholder="请输入油费"
              suffix="元"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="运费" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
              :min="0"
              style="width: 80%"
              v-model="form.freight"
              placeholder="请输入运费"
          />
          元/吨
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="利润" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input-number
              style="width: 80%"
              v-model="form.profit"
              placeholder="请输入利润"
          />
          元
        </a-form-item>
      </a-col>
      <a-col v-action:count :span="24" style="text-align: center">
        <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-button type="primary" html-type="submit" icon="search">计算</a-button>
        </a-form-item>
      </a-col>
    </a-form>
  </a-row>
</template>
<script>
import {SelectRegion} from '@/components'
import * as addressApi from '@/api/data/address'
import * as goodsApi from "@/api/data/goodsName";
import {filterParams, isEmpty} from "@/utils/util";

export default {
  name: 'searchForm',
  components: {
    SelectRegion
  },
  data() {
    return {
      searchForm: this.$form.createForm(this),
      queryParam: {},
      formLayout: 'horizontal',
      labelCol: {
        style: 'width: 70px;float:left;margin-right:10px;white-space: nowrap;\n' +
            'text-overflow: ellipsis;\n' +
            '-o-text-overflow: ellipsis;\n' +
            'overflow: hidden;'
      },
      wrapperCol: {style: 'width: calc( 95% - 70px );float:left'},

      addressList: [],
      goodsList: [],

      form: {
        start_address: [],
        end_address: [],
        kilometers: '',
        tonnage: '',
        goods_name: [],
        refuel_amount: '',
        travel_amount: '',
        wages: '',
        other_cost: [],
        other_cost_price: 0,
        cost_total: '',
        freight: '',
        profit: '',

      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler(newVal, oldVal) {
        const {
          refuel_amount,
          travel_amount,
          wages,
          other_cost_price,
          freight,
          tonnage,
          profit,
          other_cost,
        } = newVal

        var amount = 0
        other_cost.some(item => {
          if (!isEmpty(item.price)) {
            amount += item.price
          }
        })
        this.form.other_cost_price = amount

        this.form.cost_total = parseFloat((refuel_amount || 0)) + (travel_amount || 0) + (wages || 0) + (other_cost_price || 0)

        if (freight && tonnage && this.form.cost_total) {
          this.form.profit = freight * tonnage - this.form.cost_total
        }

        // if (profit && this.form.cost_total && tonnage) {
        //   this.form.freight = (profit + this.form.cost_total) / tonnage
        // }
      }
    }
  },
  mounted() {
    this.getAddress()
    this.getGoods()
  },
  methods: {
    setStartAddress(e) {
      if (e[e.length - 1]) this.form.start_address = [e[e.length - 1]]
    },
    setEndAddress(e) {
      if (e[e.length - 1]) this.form.end_address = [e[e.length - 1]]
    },
    setGoodsName(e) {
      if (e[e.length - 1]) this.form.goods_name = [e[e.length - 1]]
    },

    /**
     * 获取地址记录
     */
    async getAddress() {
      const {data: {list}} = await addressApi.getSelectList()
      this.addressList = list
    },

    /**
     * 获取货物品名
     */
    async getGoods() {
      const {data: {list}} = await goodsApi.getSelectList()
      this.goodsList = list
    },

    handleAdd() {
      this.form.other_cost.push({
        name: '', price: ''
      })
    },
    handleDel(index) {
      this.form.other_cost.splice(index, 1)
    },

    handleSubmit(e) {
      e.preventDefault()
      this.$emit('success', filterParams(this.form))
    },

    setData(data1) {
      var data = JSON.parse(JSON.stringify(data1))
      data.start_address = [data.start_address]
      data.end_address = [data.end_address]
      data.goods_name = [data.goods_name]


      data.kilometers = parseFloat(data.kilometers)
      data.refuel_amount = parseFloat(data.refuel_amount)
      data.travel_amount = parseFloat(data.travel_amount)
      data.wages = parseFloat(data.wages)
      data.other_cost_price = parseFloat(data.other_cost_price)
      data.freight = parseFloat(data.freight)
      data.tonnage = parseFloat(data.tonnage)
      data.profit = parseFloat(data.profit)
      data.cost_total = parseFloat(data.cost_total)

      delete data.create_time
      this.form = {
        ...this.form,
        ...data
      }
    },
    handleReset() {
      this.searchForm.resetFields()
    }
  }
}
</script>
<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      //height: 40px;
      width: 40%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        border: none;
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
